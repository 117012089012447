// App Configuration

const settings = {
  apiURL: "http://128.84.9.188:8090",
  siteAvailability: "public",
  samplesEndpoint: "/samples",
  trackHubPrefix: "http://genome.ucsc.edu/cgi-bin/hgTracks?db=sacCer3&hubUrl=",
};

module.exports = {
  settings: settings,
};
