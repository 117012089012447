import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ResponsivePie } from "@nivo/pie";
import { CardContent } from "@material-ui/core";

// Component styles
const styles = {
  chartContainer: {
    height: 400,
    width: 450,
    margin: "0 auto",
    padding: 0,
  },
};

function PhasePie(props) {
  const { classes } = props;

  // plot settings
  const theme = {
    fontSize: 14,
    fontFamily: "Roboto Slab",
  };
  const borderColor = { from: "color", modifiers: [["darker", 0.2]] };

  const data = [
    {
      id: "Phase-0",
      label: "Phase-0",
      value: 84,
    },
    {
      id: "Phase-I",
      label: "Phase-I",
      value: 611,
    },
    {
      id: "Phase-II",
      label: "Phase-II",
      value: 428,
    },
    {
      id: "Phase-III",
      label: "Phase-III",
      value: 138,
    },
  ];
  //   { top: 50, right: 0, bottom: 50, left: 0 }
  return (
    <CardContent className={classes.chartContainer}>
      <ResponsivePie
        data={data}
        margin={{ top: 80, right: 0, bottom: 0, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={6}
        colors={{ scheme: "paired" }}
        borderWidth={1}
        borderColor={borderColor}
        theme={theme}
        sortByValue={true}
        enableRadialLabels={false}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            translateY: -70,
            itemsSpacing: 5,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#000",
            symbolSize: 18,
            symbolShape: "square",
          },
        ]}
      />
    </CardContent>
  );
}

PhasePie.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhasePie);
