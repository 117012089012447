import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ResponsivePie } from "@nivo/pie";
import { CardContent } from "@material-ui/core";

// Component styles
const styles = {
  chartContainer: {
    height: 400,
    width: 450,
    margin: "0 auto",
    padding: 0,
  },
};

function CellLinePie(props) {
  const { classes } = props;

  // plot settings
  const theme = {
    fontSize: 14,
    fontFamily: "Roboto Slab",
  };
  const borderColor = { from: "color", modifiers: [["darker", 0.2]] };

  const data = [
    {
      id: "K562",
      label: "K562",
      value: 1009,
      color: "hsl(274, 70%, 50%)",
    },
    {
      id: "MCF7",
      label: "MCF7",
      value: 134,
      color: "hsl(155, 70%, 50%)",
    },
    {
      id: "HepG2",
      label: "HepG2",
      value: 98,
      color: "hsl(155, 70%, 50%)",
    },
    {
      id: "Breast",
      label: "Breast",
      value: 4,
      color: "hsl(221, 70%, 50%)",
    },
    {
      id: "Liver",
      label: "Liver",
      value: 8,
      color: "hsl(166, 70%, 50%)",
    },
    {
      id: "Placenta",
      label: "Placenta",
      value: 4,
      color: "hsl(26, 70%, 50%)",
    },
    {
      id: "Kidney",
      label: "Kidney",
      value: 4,
      color: "hsl(26, 70%, 50%)",
    },
  ];

  return (
    <CardContent className={classes.chartContainer}>
      <ResponsivePie
        data={data}
        margin={{ top: 80, right: 20, bottom: 0, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={6}
        colors={{ scheme: "dark2" }}
        borderWidth={1}
        borderColor={borderColor}
        theme={theme}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            translateY: -70,
            translateX: 35,
            itemsSpacing: 5,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#000",
            symbolSize: 18,
            symbolShape: "square",
          },
        ]}
      />
    </CardContent>
  );
}

CellLinePie.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CellLinePie);
