import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, CardContent, Grid } from "@material-ui/core";

// component styles
const styles = (theme) => ({});

function HeaderSection(props) {
  const { data } = props;

  return (
    <CardContent>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item sm={"auto"}>
          <Typography gutterBottom variant="h4" component="h2">
            {data.target}
            <Typography component="p" variant="caption">
              {data.fullName}
            </Typography>
            <Typography component="p" variant="caption">
              {data.aliases}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item sm={"auto"}>
          <Typography component="p" variant="body1">
            {data.description}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
}

HeaderSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderSection);
